// removed -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans",
$font-family-sans-serif:    'Lato', sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-family:  'Kristi', cursive;
$kimberly:  #ffb410;
$primary:   $kimberly;
$font-size-base:    1rem; // Assumes the browser default, typically `16px`
$h1-font-size:  $font-size-base * 3.5;
$h2-font-size:  $font-size-base * 3;
$h3-font-size:  $font-size-base * 2.75;
$h4-font-size:  $font-size-base * 2.5;
$h5-font-size:  $font-size-base * 2.25;
$h6-font-size:  $font-size-base * 2;

$input-btn-padding-y:   .75rem;
$input-btn-padding-x:   1.05rem;
$enable-rounded:    false;

$navbar-dark-toggler-icon-bg:   url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' x='0' y='0' width='46.5' height='26.7' viewBox='0 0 46.5 26.7' enable-background='new 0 0 46.5 26.667'><line fill='none' stroke='#FFFFFF' stroke-width='4' stroke-linecap='round' stroke-miterlimit='10' x1='10.8' y1='2.8' x2='43.8' y2='2.8'/><line fill='none' stroke='#FFFFFF' stroke-width='4' stroke-linecap='round' stroke-miterlimit='10' x1='43.8' y1='12.8' x2='2.8' y2='12.8'/><line fill='none' stroke='#FFFFFF' stroke-width='4' stroke-linecap='round' stroke-miterlimit='10' x1='18.8' y1='23.8' x2='43.8' y2='23.8'/></svg>");
