@import 'bootstrap/bootstrap';

/*! Leomini went crazy after this */
@import url('https://fonts.googleapis.com/css2?family=Kristi&family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300&display=swap');

html { scrollbar-width: auto; scrollbar-color: #2a3234 #f5f5f5; scroll-behavior: smooth; }
::-webkit-scrollbar-track { box-shadow: inset 0 0 6px rgba(0,0,0,0.3); background-color: #f5f5f5; }
::-webkit-scrollbar { width: 12px; }
::-webkit-scrollbar-thumb { background-color: #2a3234; }
//body { margin-top:-7.9375rem; 
//    @media (min-width: 768px) { margin-top:0rem; }
//}
.navbar { flex-wrap: nowrap;
    @media (min-width: 768px) { flex-wrap: wrap; }
}
.navbar-toggler-icon { width: 1.2em; height: 1.2em; 
    @media (min-width: 768px) { width: 1.5em; height: 1.5em; }
}

/*-- content --*/
.min-vh-100 { min-height:100vh !important; } //calc(100vh + 7.9375rem)
.vh-100 { height:100vh !important; } //calc(100vh + 7.9375rem)
.vh-75 { height:75vh !important; }
.mb-6 { margin-bottom: 6rem !important; }

div[id$="-text"] { height: 75vh; }
.ajo.book-btn { position: fixed; right: 1.2rem; bottom: 4.575rem; }
.book-btn { color:rgba(255,255,255,1); background: #2a3234 url(../img/boka-btn-left.svg) left no-repeat; display: inline-block; font-weight: 700; text-align: center; vertical-align: middle; cursor: pointer; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; padding:.74rem 1.2rem .74rem 3.75rem; font-size: 1rem; line-height: 1.4; border:none; border-radius:0; text-transform:uppercase;
    .thin { font-weight: 400; }
}
.book-btn:hover { opacity: 90%; color:rgba(255,255,255,.9); text-decoration:none; }
.stripe { background: url(../img/stripe.png) repeat-x; height: 3.375rem; }
.btn { color: $white;
    &:hover { color: $white; }
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary.active:not(:disabled):not(.disabled), .show>.btn-primary.dropdown-toggle, .btn-primary:focus, .btn-primary.focus { color: $white; }
a.text-link { background-color:rgba(42, 50, 52, .85); padding: 0 4px 4px 4px; }
h4.citat { font-family: lato; font-weight: 700; font-size: 1.325rem; line-height: 25pt; padding-right: 0rem!important; 
    @media (min-width: 768px) { font-size: 1.875rem; line-height: 40pt; }
    @media (min-width:992px) { padding-right: 2rem!important; }
}
h5.citat { font-family: lato; font-weight: 300; font-size: 1rem; line-height: 32pt; text-transform: uppercase; }
h5.citatnamn { font-family: lato; font-weight: 700; font-size: 1.2rem; 
    @media (min-width: 768px) { font-size: 1.25rem; }
}
.text-citat { padding-left: 1.2rem!important; padding-right: 1.2rem !important; 
    @media (min-width:992px) { padding-left: 6.875rem!important; padding-right: 0rem !important; }
}
.floor { height: 10%; }
img.blend { mix-blend-mode: multiply; }

section[id^="ak-"] .intro-rubbe, section[id^="ak-"] .box-text { display: flex; flex-direction: column; justify-content: center; align-items: center; }
section[id^="ak-"] h1, section[id^="ak-"] h2 { background-color:#2a3234; }
section[id^="ak-"] h1 { font-size: 2.5rem; padding: .5rem 2rem .5rem 1.5rem;
    @media (min-width:768px) { font-size: 4rem; padding: .5rem 2rem 0 1.5rem; }
}
section[id^="ak-"] h2 { font-family: 'Lato', sans-serif; color:#fff; font-weight: 300; font-size: 1.5rem; padding: 1.2rem; 
    @media (min-width:768px) { font-size: 2.5rem; }
}
section[id^="ak-"] .box-text p { font-size: 1.25rem;
    @media (min-width:768px) { font-size: 1.563rem; }
}
section[id^="ak-"] { overflow: hidden; position: relative;
    &::before {
        content: ' ';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        will-change: transform;
        z-index: -1;
    }
}
*::before.bg-fixed {position: fixed;}
#ak-intro { margin-top:-5.063rem; //background: url(../img/ak-intro-bg-m.jpg) top left fixed no-repeat; background-size: cover;
    //@media (min-width:768px) { background: url(../img/ak-intro-bg.jpg) top center fixed no-repeat; background-size: cover; }
    //@supports (-webkit-touch-callout: none) { background-attachment: scroll; }
    @media (min-width: 768px) { margin-top:0rem; } 
    h2 { transform: rotate(-.5deg); transform-origin: top left; }
    h1 { opacity:0; animation: fade-in-h1-m 0.45s 0.4s forwards;
    @media (min-width:768px) { opacity:0; animation: fade-in-h1 0.45s 0.4s forwards; }
    }
    &::before {
        background: url(../img/ak-intro-bg-m.jpg) no-repeat top center;
        background-size: cover;
        
        @media (min-width:768px) {
            background: url(../img/ak-intro-bg.jpg) no-repeat top center;
            background-size: cover;
            
        }
    }
}
#ak-intro h2 { opacity:0; animation: fade-in-h2 0.5s 0.5s forwards; }
.intro-two-text, .help-two-text, .customers-two-text, .price-two-text { opacity:0; }
.text-slide { animation: slide-down 0.5s forwards; }
section[id^="ak-"] .box-text p { background-color:rgb(42, 50, 52); }
.box-text p { color: #fff; padding: 1rem; font-size: 1.25rem; font-weight: 300; }
section[id^="ak-"] .box-text { opacity:0; }
#ak-intro .box-text p { min-width: 315px; max-width: 560px; }
#ak-help .box-text p { min-width: 315px; max-width: 580px; }
#ak-find-ak .box-text p { min-width: 315px; max-width: 465px; }
.fa-ul {padding-left: 2.5rem;
    @media (min-width:768px) {padding-left: 0;}
    }
.fa-li { font-size: 85%; margin-top: 3px; left: -.5rem; position: relative; }

#ak-help { 
    h1 { transform: rotate(-5deg) translate(75px, -7px); transform-origin: top left;
        @media (min-width:768px) { transform: rotate(-5deg) translate(200px, 10px); }
    }
    &::before {
        background: url(../img/ak-help-bg-m.jpg) no-repeat top left;
        background-size: cover;
        
        @media (min-width:768px) {
            background: url(../img/ak-help-bg.jpg) no-repeat top center;
            background-size: cover;
            
        }
    }
}
#ak-help h1, #ak-help h2 {opacity:0;}
#ak-help .h1-help-in { opacity:0; animation: fade-in-h1-help 0.45s 0.4s forwards; }
#ak-help .h2-help-in { opacity:0; animation: fade-in-h2-help 0.5s 0.3s forwards; }

#ak-customers { //background: url(../img/ak-customers-bg-m.jpg) top left fixed no-repeat; background-size: cover;
    //@media (min-width:768px) { background: url(../img/ak-customers-bg.jpg) top center fixed no-repeat; background-size: cover; }
    //@supports (-webkit-touch-callout: none) { background-attachment: scroll; }
    .row.vh-100 { height:70vh !important; 
        @media (min-width: 768px) { height:100vh !important; } //calc(100vh + 7.9375rem)
    }
    .customers-rubbe h1 { opacity:0; font-family: 'Lato'; font-size: 1.95rem; font-weight: 900; color:#2a3234; background-color: unset;
        @media (min-width:768px)
            { padding-left: 1.2rem; font-size: 2.5rem; }
        
        @media (min-width:992px)
            { padding-left: 6.875rem; }
    }
    &::before {
        background: url(../img/ak-customers-bg-m.jpg) no-repeat top center;
        background-size: cover;
        
        @media (min-width:768px) {
            background: url(../img/ak-customers-bg.jpg) no-repeat top center;
            background-size: cover;
            
        }
    }
    .h1-customers-in { animation: fade-in-h1-customers 0.45s 0.4s forwards; }
}

div[id^="quote"] { opacity: 0; }
.q-text-slide { animation: slide-up 0.5s forwards; }

#ak-price { //background: url(../img/ak-price-bg-m.jpg) top center fixed no-repeat; background-size: cover;
    //@media (min-width:768px) { background: url(../img/ak-price-bg.jpg) top center fixed no-repeat; background-size: cover; }
    //@supports (-webkit-touch-callout: none) { background-attachment: scroll; }
    #h2-top, #h1-middle, #h2-bottom { opacity:0; }
    &::before {
        background: url(../img/ak-price-bg-m.jpg) no-repeat top center;
        background-size: cover;
        
        @media (min-width:768px) {
            background: url(../img/ak-price-bg.jpg) no-repeat top center;
            background-size: cover;
            
        }
    }
}
#ak-price h2.h2-price-top { opacity:0; animation: slide-in-h2-price-m 0.45s 0.3s forwards; 
    @media (min-width:768px) { animation: slide-in-h2-price 0.45s 0.3s forwards; }
    }
#ak-price h1.h1-anim-price { opacity:0; animation: slide-in-h1-price-m 0.45s 0.4s forwards; 
    @media (min-width:768px) { animation: slide-in-h1-price 0.45s 0.4s forwards; }
    }
#ak-price h2.h2-price-bottom { opacity:0; animation: slide-in-h2-price-2-m 0.45s 0.5s forwards; 
    @media (min-width:768px) { animation: slide-in-h2-price-2 0.45s 0.5s forwards; }
    }

#ak-find-ak h1 { transform: rotate(-5deg) translate(80px, -15px); transform-origin: top left; 
    @media (min-width:768px)  { transform: rotate(-5deg) translate(110px, -15px); }
    }
#ak-find-ak h2 { transform: rotate(-.5deg); transform-origin: top left; }
#ak-find-ak h1, #ak-find-ak h2 {opacity:0;}
#ak-find-ak .h1-find-ak-in { opacity:0; animation: fade-in-h1-find-ak 0.45s 0.3s forwards; }
#ak-find-ak .h2-find-ak-in { opacity:0; animation: fade-in-h2-find-ak 0.5s 0.2s forwards; }
#ak-find-ak { //background: url(../img/find-ak-bg-m.jpg) top center fixed no-repeat; background-size: cover;
    //@media (min-width:768px) { background: url(../img/find-ak-bg.jpg) top center fixed no-repeat; background-size: cover; }
    //@supports (-webkit-touch-callout: none) { background-attachment: scroll; }
    &::before {
        background: url(../img/find-ak-bg-m.jpg) no-repeat top center;
        background-size: cover;
        
        @media (min-width:768px) {
            background: url(../img/find-ak-bg.jpg) no-repeat top center;
            background-size: cover;
            
        }
    }
}

#page-two { background:linear-gradient(to right top, rgba(230, 229, 227, 1) 0%, rgba(206, 207, 202, 1) 100%) #dedddb; padding:6rem 0 0 1.2rem;
    @media (min-width:768px) { padding: 6.875rem 0 0 6.875rem; }
}

/*-- footer --*/
footer {
    h3 { font-family: 'Lato', sans-serif; font-size: 1.75rem; font-weight: 300; }
    .fa-ul { margin-left: 1rem; margin-top: 1rem; padding-left: 0; }
}
.footer-list { 
    list-style: none; text-transform: uppercase;
    li { display: block;
        a { color: #2a3234; }
        a.book-btn { color:#fff; margin-top: 1rem; }
    }
}
.footer-list li:last-child { float:none; margin-top: 0; }
footer .fa-li { width: auto!important; font-size: 1rem; }
@media (min-width:768px) {
    footer .fa-ul { padding-left: 0; }
    .footer-list li { display: inline; margin-right: 1.6rem; }
    .footer-list li a.book-btn { margin-top: 0rem; }
    .footer-list li:last-child { float:right; margin-right: 0; margin-top: -.7rem; }
}
@media (max-width:920px) and (min-width:768px) {
    .footer-list li:last-child { margin-top: .5rem; }
}
.made-by { color: $gray-700;
    a { color: $gray-800; text-decoration: underline;
        &:hover { text-decoration: none; }
    }
}

/*-- Navbar --*/
.navbar { padding: .5rem 1rem; }
.navbar-brand img { width:65% }
body.scrolled .navbar .navbar-brand img { width:55% }
.navbar-dark { background-color: transparent!important; color:#fefefe; }
.navbar-dark { transition:400ms ease; }
body.scrolled .navbar-dark { background-color:rgba(42,50,52,.7)!important; }
body.scrolled .navbar { padding: .25rem .5rem; }
.navbar-toggler { font-size: 1.9rem; }
.navbar-dark .navbar-toggler { border-color: rgba(255, 255, 255, 0); }
@media (min-width: 768px) {
    .navbar { padding: .5rem 1.5rem; }
    .navbar-brand img { width:100% }
    .navbar-dark { background-color: transparent!important; color:#fefefe; }
    .navbar-dark { transition:400ms ease; }
    body.scrolled .navbar-dark { background-color:rgba(42,50,52,.7)!important; }
    body.scrolled .navbar { padding: .25rem .75rem; }
    body.scrolled .navbar .navbar-brand img { width:75% }
    .navbar-brand img { margin-top: 1rem; }
    body.scrolled .navbar-brand img { margin-top: 0rem; }
}

/*-- Overlay --*/
.overlay { height: 100%; width: 0; position: fixed; z-index: 99999; top: 0; left: 0; background-color: rgb(42,50,52); background-color: rgba(42,50,52,.9); overflow-x: hidden; transition: 0.4s; }
.overlay-content { position: relative; top: 15%; width: 100%; text-align: center; margin-top: 20px;
    @media (min-width: 768px) { top: 20%; margin-top: 30px; }
    }
.overlay a { padding: 8px; text-decoration: none; font-size: 32px; color: #c1c1c1; display: block; transition: 0.3s; 
    @media (min-width: 768px) { font-size: 36px; color: #b1b1b1; }
    }
.overlay a:hover, .overlay a:focus { color: #f1f1f1; }
.overlay .closebtn { position: absolute; top: 6px; right: 30px; font-size: 60px; }
body.scrolled .overlay .closebtn { position: absolute; top: -14px; right: 19px; font-size: 60px; }
.nav-icon{  font-size:30px; cursor:pointer; display: block; text-align: right; }

.fade-out { opacity: 0; transition: .8s ease;}
section[id^="ak-"] .box-text.fade-in-text { opacity: .95; transition: .8s ease;}

/*-- Animation --*/
@keyframes clear {
    to { opacity: 1; transform: none; }
}

@keyframes fade-in-h1-m {
    from { opacity: 0; transform: translateX(-60rem); }
    to { opacity: 1; transform: rotate(-5deg) translate(-85px, 15px) translateX(2rem); transform-origin: top left; }
}
@keyframes fade-in-h1 {
    from { opacity: 0; transform: translateX(-60rem); }
    to { opacity: 1; transform: rotate(-5deg) translate(-135px, 12px); transform-origin: top left; }
}
@keyframes fade-in-h2 {
    from { opacity: 0; transform: translateX(-60rem); }
    to { opacity: 1; transform: translateX(0rem) rotate(-.5deg); transform-origin: top left; }
}

@keyframes fade-in-h1-help {
    from { opacity: 0; transform: translateX(-60rem); }
    to { opacity: 1; transform: translateX(0rem) rotate(-5deg) translate(75px, -7px); transform-origin: top left; }
}

@keyframes fade-in-h1-customers {
    from { opacity: 0; transform: translateX(-60rem); }
    to { opacity: 1; transform: translateX(0rem); }
}
@keyframes fade-in-h2-help {
    from { opacity: 0; transform: translateX(-60rem); }
    to { opacity: 1; transform: translateX(0rem); }
}
@keyframes slide-in-h2-price-m {
    from { opacity: 0; transform: translateX(-35rem); }
    to { opacity: 1; transform: translate(-4rem, 15px); transform-origin: top left; }
}
@keyframes slide-in-h2-price {
    from { opacity: 0; transform: translateX(-45rem); }
    to { opacity: 1; transform: translate(-5rem, 20px); transform-origin: top left; }
}
@keyframes slide-in-h1-price-m {
    from { opacity: 0; transform: translateX(-30rem); }
    to { opacity: 1; transform: rotate(-3deg) translate(0rem, -5px); transform-origin: top left; }
}
@keyframes slide-in-h1-price {
    from { opacity: 0; transform: translateX(-50rem); }
    to { opacity: 1; transform: rotate(-3deg) translate(30px, -30px); transform-origin: top left; }
}
@keyframes slide-in-h2-price-2-m {
    from { opacity: 0; transform: translateX(-30rem); }
    to { opacity: 1; transform:  translate(3.5rem, -30px); transform-origin: top left; }
}
@keyframes slide-in-h2-price-2 {
    from { opacity: 0; transform: translateX(-50rem); }
    to { opacity: 1; transform:  translate(120px, -65px); transform-origin: top left; }
}

@keyframes fade-in-h1-find-ak {
    from { opacity: 0; transform: translateX(-60rem); }
    to { opacity: 1; transform: translateX(-2rem) rotate(-5deg) translate(80px, -15px); transform-origin: top left; }
}
@keyframes fade-in-h2-find-ak {
    from { opacity: 0; transform: translateX(-60rem); }
    to { opacity: 1; transform: translateX(-2rem); }
}

@keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes slide-down {
    from { transform: translateY(-1rem); opacity: 0; }
    to { transform: none; opacity: 1; }
}

@keyframes slide-up {
    from { transform: translateY(2rem); opacity: 0; }
    to { transform: translateY(0rem); opacity: 1; }
}